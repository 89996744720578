import React from "react"
import styled from "styled-components"

const MapWrapper = styled('div')`
  filter: grayscale(100%);
  transition: all 300ms ease-out;
  &:hover {
    filter: grayscale(0%);
  }
`

export default function() {
  return <MapWrapper>
    <iframe title="google-maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d133391.94684355464!2d24.487410449672826!3d60.28788778292018!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNjDCsDE3JzQyLjQiTiAyNMKwMzMnMjEuMSJF!5e0!3m2!1sen!2sfi!4v1610407948526!5m2!1sen!2sfi" width="100%" height="500px" frameBorder="0" style={{display: "block", border: 0}} allowFullScreen="" aria-hidden="false"></iframe>
  </MapWrapper>
}