import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { colors, Mobile, Desktop } from "../theme"
import { injectIntl } from "gatsby-plugin-intl"
import moment from "moment"
import "moment/locale/fi"
import Button from "./button"

const DesktopWrapper = styled.div`
  max-width: 55em;
  margin: 0 auto;
`
const Wrapper = styled.div`
  padding: 0 2em 4em 2em;
  &:last-child {
    margin-bottom: 4rem;
  }
`

const Title = styled.div`
  color: ${colors.kaisla};
  font-weight: bold;
  font-size: 1.25em;
`

const Date = styled.p`
  color: #969592;
  @media (max-width: 768px) {
    margin: 0.5em 0 0 0;
  }
`

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid ${colors.kaisla};
  @media (max-width: 768px) {
    border-bottom: none;
    flex-direction: column;
    align-items: flex-start;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Image = styled.img`
  max-width: 33.33%;
  max-height: 12em;
  object-fit: cover;
  margin-left: 2em;
  margin-bottom: 0;
  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 15em;
    object-fit: cover;
    margin: 0;
    margin-bottom: 1em;
  }
`

const Lead = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const ButtonContainer = styled.div`
  width: 15em;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Post = ({ title, createdAt, image, leadParagraph, slug, intl }) => {
  const isFinnish = intl.locale === "fi"
  const creationDate = moment(createdAt).format("L")
  moment.locale(isFinnish ? "fi" : "en")
  return (
    <Wrapper>
      <Desktop>
        <DesktopWrapper>
          <Heading>
            <Title>{title}</Title>
            <Date>{creationDate}</Date>
          </Heading>
          <Content>
            <Lead>
              <p>{leadParagraph.leadParagraph}</p>
              <ButtonContainer>
                <Button
                  targetPage={{ slug: "news/" + slug }}
                  title={isFinnish ? "Lue lisää" : "Read more"}
                  color={colors.pakuri}
                />
              </ButtonContainer>
            </Lead>
            <Image src={image?.localFile.childImageSharp.fixed.src} />
          </Content>
        </DesktopWrapper>
      </Desktop>
      <Mobile>
        <MobileWrapper>
          <Image src={image?.localFile.childImageSharp.fixed.src} />
          <Heading>
            <Title>{title}</Title>
            <Date>{creationDate}</Date>
          </Heading>
          <Content>
            <Lead>
              <p>{leadParagraph.leadParagraph}</p>
              <ButtonContainer>
                <Button
                  targetPage={{ slug: "news/" + slug }}
                  title={isFinnish ? "Lue lisää" : "Read more"}
                  color={colors.pakuri}
                />
              </ButtonContainer>
            </Lead>
          </Content>
        </MobileWrapper>
      </Mobile>
    </Wrapper>
  )
}

export const query = graphql`
  fragment Post on ContentfulPost {
    slug
    createdAt
    title
    leadParagraph {
      leadParagraph
    }
    image {
      localFile {
        childImageSharp {
          fixed(width: 850) {
            src
          }
        }
      }
    }
    content {
      raw
    }
    node_locale
  }
`

export default injectIntl(Post)
