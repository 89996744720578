import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Slider from "react-slick"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { TranslationContext } from "../translate"


const SliderWrapper = styled.div`
  width: calc(100% + 1em);
  max-width: 100vw;
  overflow: hidden;
  margin-right: -1em;
`



const ReviewPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
`


const ReviewPlaceholderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 1em);
  height: 100%;
  text-align: center;
  color: #{colors.pakuri};
  font-style: italic;
  line-height: 1.5em;
  padding: 1em;
  box-sizing: border-box;
  font-family: Gelasio, Georgia, serif;
`


const QuotesHeader = styled.h2`
  font-weight: 400;
  font-size: 1.8em;
  line-height: 1.25em;
  margin: 0.75em 0;
  text-align: center;
  margin-bottom: -0.5em;
  width: 100%;
`


export default function({title, contents}) {
    const breakpoints = useBreakpoint()
    const isDesktop = !breakpoints.sm
    return <TranslationContext.Consumer>
    {(t) =>
        <>
            <QuotesHeader>{t(title)}</QuotesHeader>
            <SliderWrapper>
                <Slider 
                slidesToShow={isDesktop ? 3 : 1} 
                slidesToScroll={1} 
                infinite 
                autoplay 
                speed={750} 
                adaptiveHeight={true}
                autoplaySpeed={7000} 
                pauseOnHover={false}
                arrows={false}
                >
                    {contents.map((content, i) => 
                        <ReviewPlaceholder key={i}><ReviewPlaceholderContent>"{t(content)}"</ReviewPlaceholderContent></ReviewPlaceholder>
                    )}
                </Slider>
            </SliderWrapper>
        </> 
    }
    </TranslationContext.Consumer>
}

export const query = graphql`
  fragment Quotes on ContentfulQuotes {
    title
    contents
  }
`
