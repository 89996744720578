import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { colors, WrappedWords } from "../theme"
import Reference from "./reference"
import {StyledInternalPrimaryButton, StyledExternalPrimaryButton} from "./button"
import { TranslationContext } from "../translate"
import { FormWrapper, FieldWrapper, RequiredHint, RequiredIndicator } from "./form"

const Wrapper = styled.div`
    background: ${({background}) => colors.background[background]};
    color: ${({background}) => (background === "Dark") ? "#ffffff" : "#4a4b48"};
    h2 {
      color: ${({background}) => (background === "Dark") ? colors.kaisla : "inherit"};
    }
    ${StyledInternalPrimaryButton} + &,
    ${StyledExternalPrimaryButton} + & {
      padding-top: 3em;
    }
    @media (min-width: 769px) {
    }
`

export const StyledPageContent = styled.div`
  padding: 2em 2em 4em 2em;
  max-width: 60em;
  margin: 0 auto;
  h1, h3, .lead-paragraph {
    color: ${({color}) => color ? colors[color] : "inherit"};
  }
  .lead-paragraph {
    font-style: italic;
    font-size: 1.2em;
    text-align: center;
    margin: 3em 0;
    line-height: 1.5em;
  }
  ${FormWrapper} {
    background: transparent;
    flex: 1;
    padding: 0;
    margin: 1em 0;
    label {
      border: none;
      margin-bottom: 0.5em;
      font-size: 0.8em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    input, textarea {
      border: 1px solid #000;
    }
    textarea {
      min-height: 2em;
      margin: 0;
    }
    button {
      border: 1px solid ${colors.pakuri};
      background: transparent;
      color: ${colors.pakuri} !important;
      font-size: 0.9em;
      line-height: 1em;
      padding: 0.6em 1.2em;
      font-weight: 400 !important;
      font-style: normal !important;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-decoration: none !important;
      &:hover {
        background: ${colors.pakuri};
        color: #fff !important;
      }
    }
  }
  ${FieldWrapper} {
    flex-direction: column;
    width: 100% !important;
  }
  ${RequiredHint}, ${RequiredIndicator} {
    display: none;
  }
`

export const PageTitle = styled.h1`
  font-size: 3em;
  font-weight: 200;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  text-align: center;
  text-transform: uppercase;
  span:last-child {
      font-weight: 900;
  }
  @media (min-width: 769px) {
    font-size: 5em;
  }
`

export const PageSubtitle = styled.h2`
  font-size: 1.8em;
  font-weight: 400 !important;
  text-align: center;
  color: #969592;
  text-align: ${({align}) => align} !important;
  margin-top: 0.6em;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  @media (min-width: 769px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export default function({title, background, identifier, childElements, showTitle}) {
    return <TranslationContext.Consumer>{(t) =>
      <Wrapper background={background || "White"} id={identifier}>
        <StyledPageContent>
            {showTitle && <PageTitle><WrappedWords text={t(title)} /></PageTitle>}
            <Contents>
                {childElements && childElements.map((item, i) => <Reference key={i} {...item} />)}
            </Contents>
        </StyledPageContent>
    </Wrapper>
  }</TranslationContext.Consumer>
}

export const query = graphql`
  fragment PageSection on ContentfulPageSection {
    title
    background
    identifier
    showTitle
    childElements {
        __typename
        ...FeatureList
        ...TextBlock
        ...Card
        ...ImageList
        ...Quotes
        ...Form
    }
  }
`