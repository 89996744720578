import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Reference from "./reference"
import { colors, Filler } from "../theme"
import ArrowIcon from "./arrow-icon"
import { IntlContextConsumer } from "gatsby-plugin-intl"
import { TranslationContext } from "../translate"

export const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: ${({type}) => type === "textarea" ? "flex-start" : "stretch"};
    margin-bottom: 1em;
    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: ${({type}) => type === "textarea" ? "0" : "1em"};
    }
    @media (min-width: 769px) {
      flex-direction: ${({type}) => type === "textarea" ? "column" : "row"};
      width: ${({type}) => type === "textarea" ? "100%" : "calc(50% - 1em) !important"};
      label {
        border-bottom: ${({type}) => type === "textarea" ? "none" : "1px solid " + colors.pakuri};
      }
    }
`

const InputWrapper = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    align-self: stretch;
    align-items: stretch;
    justify-content: stretch;
`

const TextInput = styled.input`
    border: none;
    font-size: 1.2em;
    padding: 0.5em;
    flex: 1;
    background: ${colors.background.White};
    border-bottom: 1px solid ${colors.kaisla};
`

const Textarea = styled.textarea`
    border: none;
    font-size: 1.2em;
    padding: 0.5em;
    background: ${colors.background.White};
    resize: none;
    box-sizing: border-box;
    width: 100%;
    min-height: 6em;
    margin: 0.5em 0;
`

export const RequiredIndicator = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    border: 12px solid transparent;
    border-top-color: ${colors.kaisla};
    border-right-color: ${colors.kaisla};
`

export function FormField({title, type, required}) {
  return <TranslationContext.Consumer>{(t) =>
    <FieldWrapper type={type}>
        <label>{t(title)}</label>
        <InputWrapper>
          {type === "text" &&
            <TextInput type="text" name={title} required={!!required} />}
          {type === "textarea" &&
            <Textarea name={title} required={!!required}></Textarea>}
          {required && <RequiredIndicator />}
        </InputWrapper>
    </FieldWrapper>
  }</TranslationContext.Consumer>
}

export const FormWrapper = styled.div`
    background: ${colors.background.Light};
    padding: 4em 2em;
    @media (min-width: 769px) {
    }
`

const FormContent = styled.form`
    position: relative;
    max-width: 55em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    @media (min-width: 769px) {
        padding-top: 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        & > * {
            width: 100%;
        }
    }
    & > :first-child > :first-child {
      margin-top: 0;
      padding-top: 0;
    }
    & > *:not(${FieldWrapper}) {
      flex: 0 0 100%;
    }
`

const SubmitButton = styled.button`
  border: 1px solid ${colors.kaisla};
  background: ${colors.kaisla};
  color: #fff !important;
  font-size: 0.9em;
  padding: 0.6em 0.8em;
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  width: 100%;
  max-width: 15em;
  &:hover {
    color: ${colors.kaisla} !important;
    background: #fff;
  }
`

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1em;
`

export const RequiredHint = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 1.5em;
    padding-top: 0.4em;
    width: auto !important;
    color: ${colors.kaisla};
    span {
      font-size: 0.8em;
    }
`

export default function({title, formFields, submitButtonText}) {
  const requiredFieldMsg = {
    fi: "pakollinen kenttä",
    en_US: "required field"
  }
  return <TranslationContext.Consumer>{(t) =>
    <FormWrapper>
      <FormContent data-netlify={true} method="POST" name={title}>
        {formFields && formFields.map((field, i) => <Reference key={i} {...field} />)}
        <input type="hidden" name="form-name" value={title} />
        <SubmitRow>
            <Filler />
            <SubmitButton type="submit">{t(submitButtonText)}<ArrowIcon /></SubmitButton>
        </SubmitRow>
        <RequiredHint>
          <span>
            <IntlContextConsumer>
              {({language}) => requiredFieldMsg[language] || requiredFieldMsg["en_US"]}
            </IntlContextConsumer>
          </span>
          <RequiredIndicator />
        </RequiredHint>
      </FormContent>
  </FormWrapper>
  }</TranslationContext.Consumer>
}

export const query = graphql`
  fragment FormField on ContentfulFormField {
    title
    type
    required
  }
  fragment Form on ContentfulForm {
    title
    formFields {
        __typename
        ...FormField
        ...TextBlock
    }
    submitButtonText
  }
`

