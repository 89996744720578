import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Lightbox from "react-image-lightbox"
import { colors } from "../theme"
import "react-image-lightbox/style.css"
import { TranslationContext } from "../translate"

const Title = styled.h3`
    font-size: 1.5em;
    padding: 0.5em 0;
    margin: 0 0 0.5em 0;
    border-bottom: 3px solid currentColor;
    font-weight: bold;
    color: ${colors.kaisla};
`

const Wrapper = styled.div`
    padding: 0em 2em 2em 2em;
    max-width: 55em;
    margin: 0 auto;
`

const Images = styled.div`
    margin-left: -0.25em;
    margin-right: -0.25em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0em;
    &:after {
      content: "";
      flex: auto;
      display: block;
    }
    @media (min-width: 768px) {
    }
`

const Image = styled.img`
    margin: 0.25em;
    width: calc(50% - 0.5em);
    cursor: pointer;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    &:nth-child(n+3) {
      width: calc(33.33% - 0.5em);
    }
    @media (min-width: 768px) {
        width: calc(33.33% - 0.5em);
        &:nth-child(n+4) {
          width: calc(16.66% - 0.5em);
        }
    }
`

export default function({title, content, images}) {
  const [currentLightboxImageIndex, setCurrentLightboxImageIndex] = useState(null)
  const imageSrcs = images.filter((img => img && img.localFile)).map(i => i.localFile.childImageSharp.large.src)
  return <Wrapper>
    <TranslationContext.Consumer>{(t) => <>
    <Title>{t(title)}</Title>
    {content && renderRichText(t(content, true))}
    <Images>
      {images.filter((img => img && img.localFile)).map((image, index) => {
        let src = index < 3 ? image.localFile.childImageSharp.medium.src : image.localFile.childImageSharp.small.src
        return <Image onClick={(e) => {e.preventDefault(); setCurrentLightboxImageIndex(index)}} src={src} />
      })}
    </Images>
    {(currentLightboxImageIndex !== null) &&
        <Lightbox
        mainSrc={imageSrcs[currentLightboxImageIndex]}
        nextSrc={imageSrcs[(currentLightboxImageIndex + 1) % imageSrcs.length]}
        prevSrc={imageSrcs[(currentLightboxImageIndex + imageSrcs.length - 1) % imageSrcs.length]}
        onMoveNextRequest={() =>
            setCurrentLightboxImageIndex((currentLightboxImageIndex + 1) % imageSrcs.length)
          }
          onMovePrevRequest={() =>
            setCurrentLightboxImageIndex((currentLightboxImageIndex + imageSrcs.length - 1) % imageSrcs.length)
          }
        onCloseRequest={() => setCurrentLightboxImageIndex(null)}
        />
    }
    </>}</TranslationContext.Consumer>
  </Wrapper>
}

export const query = graphql`
  fragment ImageGallery on ContentfulImageGallery {
    title
    content {
      raw
    }
    images {
      localFile {
        childImageSharp {
          large: fixed(width: 1500) {
            src
          }
          medium: fixed(width: 691) {
            src
          }
          small: fixed(width: 346) {
            src
          }
        }
      }
    }
  }
`
