import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { colors } from "../theme"
import { TranslationContext } from "../translate"

const Wrapper = styled.div`
    background: #fff;
    padding: 2em;
    text-align: center;
    border-top: 3px solid ${colors.kaisla};
    position: relative;
    box-shadow: 0 0.5em 1em rgba(0,0,0,0.02);
    margin-top: 3em;
    @media (min-width: 769px) {
        flex: 1;
        & + & {
            margin-left: 3em;
        }
    }
`

const Title = styled.div`
    color: ${colors.kaisla};
    font-weight: bold;
    letter-spacing: 0.1em;
    font-size: 1.5em;
    text-transform: uppercase;
    margin-top: 1em;
    margin-bottom: 0.5em;
`

const Feature = styled.div`
    border-bottom: 1px solid #e0e0e0;
    padding: 0.75em 0;
    &:last-child {
        border-bottom: none;
    }
    @media (min-width: 769px) {
    }
`

const Icon = styled.img`
    position: absolute;
    width: 4em;
    top: -2em;
    left: 50%;
    margin-left: -2em;
`

export default function({title, features, icon}) {
  return <Wrapper>
    <TranslationContext.Consumer>{(t) => <>
      {icon && <Icon src={icon.localFile.childImageSharp.fixed.src} />}
      <Title>{t(title)}</Title>
      {features.map((feature) => <Feature>{t(feature)}</Feature>)}
    </>}</TranslationContext.Consumer>
  </Wrapper>
}

export const query = graphql`
  fragment FeatureList on ContentfulFeatureList {
    title
    icon {
      localFile {
        childImageSharp {
          fixed(width: 200) {
            src
          }
        }
      }
    }
    features
  }
`
