import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import get from "lodash/get"
import Slider from "react-slick"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const SliderWrapper = styled.div`
  width: calc(100% + 1em);
  max-width: 100vw;
  overflow: hidden;
  margin-top: -4em;
  margin-right: -1em;
`

const SliderImageWrapper = styled('div')`
  display: block;
  width: 100%;
  position: relative;
  padding-top: 60%;
`

const SliderImage = styled.img`
  object-fit: cover;
  width: calc(100% - 1em);
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`


const Images = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`

const Image = styled.img`
    height: auto;
    width: auto;
    object-fit: contain;
`

export default function({images, imageStyles, wrapperStyles, slider, visibleSlideAmount}) {
  const breakpoints = useBreakpoint()
  const isDesktop = !breakpoints.sm
  if(slider) {
    return <SliderWrapper>
      <Slider 
        slidesToShow={isDesktop ? visibleSlideAmount : 1} 
        slidesToScroll={1} 
        infinite 
        autoplay 
        speed={750} 
        autoplaySpeed={3000} 
        pauseOnHover={false}
        arrows={false}
      >
        {images.filter((img => img && img.localFile)).map((image, i) => {
          return <SliderImageWrapper key={i}><SliderImage src={image.localFile.childImageSharp.fixed.src} /></SliderImageWrapper>
        })}
      </Slider>
    </SliderWrapper>
  }

  return <Images style={JSON.parse(get(wrapperStyles, "internal.content", "{}"))}>
    {images.filter((img => img && img.localFile)).map((image, i) => {
      return <Image key={i} src={image.localFile.childImageSharp.fixed.src} style={JSON.parse(get(imageStyles, "internal.content", "{}"))} />
    })}
  </Images>
}

export const query = graphql`
  fragment ImageList on ContentfulImageList {
    images {
      localFile {
        childImageSharp {
          fixed(width: 500) {
            src
          }
        }
      }
    }
    imageStyles {
      internal {
        content
      }
    }
    wrapperStyles {
      internal {
        content
      }
    }
    slider
    visibleSlideAmount
  }
`
