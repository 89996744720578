// Render a Contentful reference with a matching component

import React from "react"
import Card from "./card"
import Button from "./button"
import FeatureList from "./feature-list"
import TextBlock from "./text-block"
import PageSection from "./page-section"
import Post from "./post"
import ImageGallery from "./image-gallery"
import Form, { FormField } from "./form"
import ImageList from "./image-list"
import Quotes from "./quotes"

export default function (props) {
  const mapping = {
    ContentfulCard: Card,
    ContentfulFeatureList: FeatureList,
    ContentfulTextBlock: TextBlock,
    ContentfulPageSection: PageSection,
    ContentfulPost: Post,
    ContentfulButton: Button,
    ContentfulForm: Form,
    ContentfulFormField: FormField,
    ContentfulImageGallery: ImageGallery,
    ContentfulImageList: ImageList,
    ContentfulQuotes: Quotes,
  }
  const Component = mapping[props.__typename]
  if (!Component) {
    return null
  }
  return <Component {...props} />
}
